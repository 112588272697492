import { createContext } from "react";
export type ConnectionProviderContext = {
    lastUpdate: number;
    setLastUpdate: (time: number) => void
    roon: string
    setRoon: (url: string) => void
    plex: string
    setPlex: (url: string) => void
}

export const ConnectionContext = createContext<ConnectionProviderContext>({} as ConnectionProviderContext)